import InterceptorName from './interceptor/InterceptorName';
import {
  ApiEvents,
  PublicDispatchEvent,
  SourceSetOptions
} from '@kollective-frontend/ksdk-api';
import {
  AttachmentRequestRecord,
  ClusterDetachRecord,
  ConnectionRecord,
  ContentNotificationRecord,
  NodeStateRecord,
  PeerConnection,
  PeerRequestRecord,
  PendingObject,
  SourceType
} from './webrtc/agent/interfaces';
import { DeprecatedEvents } from './public/events';
import { ObjectRequest, ObjectResponse } from './interceptor/interfaces';
import { ILocality } from './locality/Locality';
import { IPlugin } from './common/interfaces';
import { MaybeContentConfig } from './config/ContentConfig';
import { MaybeGeneralBlock, Ecdn11Block } from './reporter/report/Report';
import { MaybeLocalState } from './state/LocalState';
import { NodeUpdateEvent } from './events/NodeUpdateEvent';
import { Record as TransferRecord } from './webrtc/agent/TransferRecord';
import { ReporterMessage } from './log/ReporterAppender';
import { ReporterSections } from './common/Reporter/constants';
import { TriggerAction } from './common/Reporter/interfaces';
import { ProxyType } from './config/ProxyConfig';
import {
  PlayerEvent,
  PrimingEvent,
  DetectionEvent
} from './reporter/constants';

// NOTE - All internal event names should be camel case leading with a lower
// case char.
// Internal Events are NOT "advertised" events. They can be subscribed to, but
// we do not make any guarantees about their future availability.
export enum InternalEvents {
  // Only on MS integration
  START_DELIVERY_READY = 'startDeliveryReady',

  LOAD_AGENT_DATA = 'loadAgentData', // <-- Not implemented yet
  PLAYER_EVENT = 'playerEvent',
  PLAYER_READY = 'playerReady',
  DETECTION_EVENT = 'detection',
  PRIMING_EVENT = 'priming',
  SOURCE_SET = 'sourceSet',

  // For testing via test integration, allows override of config values
  // when listening to an event
  CONFIG_UPDATE = 'configUpdate',

  UPDATE_REPORT = 'updateReport',
  UPDATE_STATE = 'updateState',
  TRIGGER = 'triggerChange',
  INITIALIZE_REPORTER = 'initializeReporter',
  INTERCEPTOR_CHANGE = 'interceptorChange',

  // Reporting Events
  LOG = 'log',
  DIAGNOSTIC = 'diagnostic',
  TRANSFER_RECORD = 'transferRecord',
  CONNECTION_RECORD = 'connectionRecord',
  PEER_NOTIFICATION = 'peerNotification',
  ATTACHMENT_REQUEST = 'attachementRequest',
  NODE_STATE = 'nodeState',
  CONTENT_NOTIFICATION = 'contentNotification',
  PEER_REQUEST = 'peerRequest',
  DETACH = 'detach',
  REQUEST_INGEST = 'requestIngest',
  PROXY_INGEST = 'proxyIngest',

  // state events
  NODE_UPDATE = 'nodeUpdate',

  // Agent Notifications
  PENDING_OBJECT = 'pendingObject',

  // HLS Playlist data
  MASTER_PLAYLIST_OID_DATA = 'masterPlaylistOidData',
  MEDIA_PLAYLIST_OID_DATA = 'mediaPlaylistOidData'
}

export type Events = ApiEvents | DeprecatedEvents | InternalEvents;

export interface ActionEvent {
  action: TriggerAction;
}

export interface DiagnosticEventData {
  message?: string;
  error: unknown;
  data?: Record<string, string>;
  code: number;
  from: string;
}

export interface DiagnosticEvent {
  type: InternalEvents.DIAGNOSTIC;
  data: DiagnosticEventData;
}

export interface ReporterEvent {
  value: MaybeGeneralBlock | ILocality | Ecdn11Block;
  section: ReporterSections;
  override?: boolean;
}

export interface InitializeReporterData {
  contentId?: string;
  items?: unknown; // See: contentSources
  legacy?: unknown;
  seed?: string; // MsftPlugin only
  tenantId?: string;
  token?: string;
  user?: string;
  userEmail?: string;
}

// Msft Plugins only
export type DeliveryFinishTypes = { type: 'sourceset' | 'load' };

// RequestIngest
export interface RequestIngestError {
  type: InternalEvents.REQUEST_INGEST;
  error: {
    request?: ObjectRequest;
    modifyRequest?: ObjectRequest;
    error: Error;
  };
}

export interface RequestIngestData {
  type: InternalEvents.REQUEST_INGEST;
  data: ObjectResponse;
  cacheOnly?: boolean;
}

export interface RequestIngestDataError extends RequestIngestData {
  error: Error;
}

export type RequestIngestBaseItem = RequestIngestDataError | RequestIngestData;

export type RequestIngestItem = RequestIngestBaseItem | RequestIngestError;

export interface ProxyIngestData {
  type: InternalEvents.PROXY_INGEST;
  data: ObjectResponse;
  sourceType: SourceType;
  proxyType: ProxyType;
}

export interface ProxyIngestError extends ProxyIngestData {
  error: Error;
}

export type ProxyIngestItem = ProxyIngestError | ProxyIngestData;

interface ContentNotificationEvent {
  type: InternalEvents.CONTENT_NOTIFICATION;
  data: ContentNotificationRecord;
}
export interface InterceptorChangeEvent {
  name: InterceptorName;
  add: boolean;
}
export interface PlayerEventItem {
  type: InternalEvents.PLAYER_EVENT;
  data: PlayerEvent;
}

export interface RuntimeDetectionEventItem {
  type: InternalEvents.DETECTION_EVENT;
  data: DetectionEvent;
}

export interface PrimingEventItem {
  type: InternalEvents.PRIMING_EVENT;
  data: PrimingEvent;
}

export interface SourceSetEventOptions<T> {
  url: string;
  options?: SourceSetOptions<T>;
  resolve: () => void;
  reject: (e?: Error) => void;
}

export interface SourceSetEvent {
  type: InternalEvents.SOURCE_SET;
  data: SourceSetEventOptions<unknown>;
}

export type PlayerDetectionOrPrimingEventItem =
  | PlayerEventItem
  | RuntimeDetectionEventItem
  | PrimingEventItem;

export type KsdkDispatchEvent =
  | PublicDispatchEvent
  | DiagnosticEvent
  | { type: InternalEvents.ATTACHMENT_REQUEST; data: AttachmentRequestRecord }
  | { type: InternalEvents.CONNECTION_RECORD; data: ConnectionRecord }
  | ContentNotificationEvent
  | { type: InternalEvents.CONFIG_UPDATE; data: MaybeContentConfig }
  | { type: InternalEvents.DETACH; data: ClusterDetachRecord }
  | { type: InternalEvents.INITIALIZE_REPORTER; data: InitializeReporterData }
  | { type: InternalEvents.INTERCEPTOR_CHANGE; data: InterceptorChangeEvent }
  | { type: InternalEvents.LOAD_AGENT_DATA; data: unknown } // <-- Not Available
  | { type: InternalEvents.LOG; data: ReporterMessage }
  | { type: InternalEvents.NODE_STATE; data: NodeStateRecord }
  | { type: InternalEvents.NODE_UPDATE; data: NodeUpdateEvent }
  | { type: InternalEvents.PEER_NOTIFICATION; data: PeerConnection }
  | { type: InternalEvents.PEER_REQUEST; data: PeerRequestRecord }
  | { type: InternalEvents.PENDING_OBJECT; data: PendingObject }
  | { type: InternalEvents.PLAYER_READY; data: { item?: IPlugin } }
  | PlayerEventItem
  | PrimingEventItem
  | ProxyIngestItem
  | RequestIngestItem
  | RuntimeDetectionEventItem
  | SourceSetEvent
  | { type: InternalEvents.START_DELIVERY_READY; data: DeliveryFinishTypes }
  | { type: InternalEvents.TRANSFER_RECORD; data: TransferRecord }
  | { type: InternalEvents.TRIGGER; data: ActionEvent }
  | { type: InternalEvents.UPDATE_REPORT; data: ReporterEvent }
  | { type: InternalEvents.UPDATE_STATE; data: MaybeLocalState };

export const ReplacementEvents: Record<string, string> = Object.freeze({
  [DeprecatedEvents.IP_MDNS]: ApiEvents.IP_TYPE,
  [DeprecatedEvents.WEBSOCKET_CONNECTION]: ApiEvents.WEBSOCKET_CONNECT
});

export const allEvents = [
  ...Object.values(ApiEvents),
  ...Object.values(DeprecatedEvents),
  ...Object.values(InternalEvents)
];
