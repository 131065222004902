import { MaybeContentConfig } from '../config/ContentConfig';
import { getOperationV0_1 } from './discovery/getOperation';
import replaceTemplateString from './util/replaceTemplateString';
import FailedRequestError from './FailedRequestError';
import SDKConfig from '../common/SDKConfig';

export interface GetSdkUriResponse extends MaybeContentConfig {
  disabled?: boolean;
}

export interface GetSdkUriArgs {
  apiHost?: string;
  envHostSuffix: string;
  tenantId: string;
  token: string;
  requestInit?: RequestInit;
}

export default async function getSdkUri(
  opts: GetSdkUriArgs
): Promise<GetSdkUriResponse | undefined> {
  const { tenantId, envHostSuffix, apiHost } = opts;
  // In this case we have no 11 config. So we fall back to "latest"
  if (!tenantId || (!envHostSuffix && !apiHost)) {
    return Promise.resolve(undefined);
  }
  const op = 'getSdkUri';
  const { operations } = await getOperationV0_1(op, envHostSuffix, apiHost);
  const { uri } = operations[op];
  const url = new URL(replaceTemplateString(uri, { tenantId }));
  let requestInit: RequestInit = {
    ...opts.requestInit,
    mode: 'cors'
  };
  requestInit = {
    ...requestInit,
    headers: {
      ...requestInit.headers,
      Authorization: `Bearer ${opts.token}`
    }
  };
  const ksdkVersion = new SDKConfig().version;
  if (ksdkVersion) {
    requestInit.headers = {
      ...requestInit.headers,
      'X-Kollective-KSDKVersion': ksdkVersion
    };
  }
  const resp = await fetch(url.toString(), requestInit);
  if (resp.ok) {
    return await resp.json();
  } else if (resp.status >= 400 && resp.status < 500) {
    return Promise.resolve({ disabled: true });
  } else {
    return Promise.reject(new FailedRequestError(resp.status));
  }
}
